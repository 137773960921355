<template>
    <div id="mv-footer" class="footer-mv">
        <div class="container">
            <div class="footer-content">
                <div class="footer-phone" v-if="!Global.isAgentCustomerView">
                    <span class="company">{{ $t("roojaiName") }}</span>
                    <a
                        href="tel:02150890821"
                        role="button"
                        class="btn btn-link"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M1.99463 3.46077C1.99463 2.7244 2.59214 2.12744 3.3292 2.12744H5.51739C5.80461 2.12744 6.05961 2.31106 6.15043 2.58329L7.1499 5.57892C7.25491 5.89366 7.11229 6.23765 6.81528 6.38602L5.30904 7.13844C6.04455 8.76825 7.35879 10.0813 8.99012 10.8161L9.74324 9.31126C9.89175 9.01452 10.2361 8.87204 10.5511 8.97695L13.5495 9.97549C13.822 10.0662 14.0058 10.321 14.0058 10.6079V12.7941C14.0058 13.5305 13.4083 14.1274 12.6712 14.1274H12.0039C6.47594 14.1274 1.99463 9.65029 1.99463 4.12744V3.46077Z" stroke="#EF4A24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span style="margin-left:4px;"> 021 5089 0821 </span>
                    </a>
                </div>
                <div class="footer-compro" v-if="!Global.isAgentCustomerView">
                    <span style="font-weight: 700;margin-bottom: 4px;display: block;">
                        {{ $t("roojaiAddressLine1") }}
                    </span> 
                    <span>{{ $t("roojaiAddressLine2") }}</span>   
                </div>
                <div class="footer-image">
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/ojk.png"
                        alt="ojk"
                        class="lazy"
                    />
                    <img style="padding-left: 5px;margin-top: 8px;"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/sompo-logo.svg"
                        alt="sompo"
                        class="lazy"
                    />
                </div>
                <div class="footer-image">
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/logo-virtual-account.png"
                        alt="virtual-account"
                        class="lazy"
                    />
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/logo-visa.png"
                        alt="visa"
                        class="lazy"
                    />
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/logo-mastercard.png"
                        alt="mastercard"
                        class="lazy"
                    />
                    <img style="padding-left: 5px"
                        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        data-src="images/JCB-settings.svg"
                        alt="mastercard"
                        width="59"
                        height="46"
                        class="mx-auto lazy"
                    />
                </div>
            </div>
        </div>
        <div class="footer-company" v-if="!Global.isAgentCustomerView">
            ©{{ new Date().getFullYear() }} PT Roojai Services Indonesia Copyright
        </div>
    </div>
</template>

<script>
import Global from "@/store/global.js";
export default {
    name: "FooterMV",
    data() {
        return {
            Global,
        }
    }
}
</script>

<style scoped>
.footer-mv {
    background: #fff;
    border-top: 2px solid #E8E8E8;
}
.footer-mv .footer-content {
    padding: 32px 0px 32px 0px;
}
.footer-mv .footer-content .footer-phone {
    text-align: center;
}
.footer-mv .footer-content .footer-compro {
    font-family: 'Noto Sans';
    text-align: center;
    padding: 24px;
    color: #000000;
}
.footer-mv .footer-content .footer-image {
    text-align: center;
    margin-bottom: 8px;
}

.footer-mv .footer-content .footer-image:last-child {
    text-align: center;
}
.footer-mv .footer-content .footer-image img {
    height: 32px;
    margin-right: 10px;
}
.footer-mv .footer-company {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background: #003B71;
    color: #fff;
    padding: 16px 24px;
}
.company{
    font-family: 'Noto Sans';
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
    color: #003B71;
}

.btn-link {
    padding: 6px 16px;
    width: 170px;
    height: 36px;
    /* background: linear-gradient(180deg, #EF4A24 0%, #F26848 100%); */
    background: #FFEFEB;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #EF4A24!important;
    border-radius: 8px;
    transition: background 0.5s step-start;
}
.btn-link:hover {
    background: #FFE7E1;
}
.btn-link img{
    fill: #EF4A24;
}
.btn-link:hover {
    text-decoration: none;
    /* background: #C73E1E; */
}
@media screen and (max-width: 480px) {
    .footer-mv .footer-content .footer-image img {
        height: 24px;
        margin-right: 10px;
    }
}
</style>