<template>
    <div class="headerMv" :class="{stickyStyle: sticky}">
        <div class="container">
            <div class="headerContent">
                <div class="backButtonMv" :class="{backwidth: !showBackButton}">
                    <button class="back" @click="back" v-if="showBackButton">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path d="M10.0088 12.6668L5.33779 8.00016L10.0088 3.3335" stroke="#EF4A24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>                        
                        {{ $t('motorVehicle.back') }}
                    </button>
                </div>
                <div class="logoBrand">
                    <div class="logo">
                        <img
                            :src="roojaiLogo"
                            :data-src="roojaiLogo"
                            alt="roojai.co.id"
                            class="lazy"
                            height="38"
                            width="58"
                        />
                        <img
                            :src="sompoLogo"
                            :data-src="sompoLogo"
                            alt="roojai.co.id"
                            class="lazy"
                            height="38"
                            width="71"
                        />
                    </div>
                </div>
                <div class="language">
                    <div class="switch">
                        <input id="language-toggle" class="check-toggle check-toggle-round-flat" type="checkbox" v-model="isEnglish" @input="toogleChangeLanguage" >
                        <label for="language-toggle"></label>
                        <span class="on">ID</span>
                        <span class="off">EN</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="stepper-header">
            <Stepper 
                :steps="step"
                :language="selectedLanguage.value"
                v-if="progressbar"
                :isShowLabel="labelStepper"
            />
        </div>
        
    </div>
    
</template>
<script>
import roojai from "../../../../public/images/roojai-logo-id.svg";
import sompo from "../../../../public/images/sompo-logo.svg";
import Stepper from "./Stepper.vue";
import Global from "@/store/global.js";

export default {
    name: "HeaderMv",
    props: {
        sticky: {
            type: Boolean,
            default: false
        },
        showing:{
            type: Boolean,
            default: false
        },
        step:{
            type: Number,
            default: 1
        },  
        progressbar: {
            type: Boolean,
            default: false
        },
        language: {
            type: String,
            default: 'id'
        },
        labelStepper: {
            type: Boolean,
            default: true
        },
        prefLang:{
            type: String,
            default: null
        }   
    },
    components: {
        Stepper,
    },
    data() {
        return {
            roojaiLogo: roojai,
            sompoLogo: sompo,
            isEnglish: false,
            selectedLanguage: null,
            firstVisit: true,
            English : null,
            Indonesian : null,
            Global,
            languages: [
                {
                    value: "id",
                    text: "Bahasa",
                    icon: "images/motor-vehicle/id.svg"
                },
                {
                    value: "en",
                    text: "Eng",
                    icon: "images/motor-vehicle/en.svg"
                }
            ],
            isDropdown: false,
        }
    },
    created() {
        // document.addEventListener('click', this.documentClick);
        this.English = this.languages.find(({ value }) => value === "en");
        this.Indonesian = this.languages.find(({ value }) => value === "id");
        let findLang = this.languages.find(({ value }) => value === this.prefLang) ?? this.languages[0];
        this.selectedLanguage = findLang
        this.isEnglish = (this.prefLang == 'id' ? false : true)
    },
    updated(){
        if (!this.firstVisit) {
            let findLang = this.languages.find(({ value }) => value === this.prefLang.toLowerCase()) ?? this.languages[0];
            this.$emit("selectedLang", findLang);
        }
    },
    destroyed() {
        // document.removeEventListener('click', this.documentClick);
    },
    methods: {
        changeLanguage(value) {
            this.selectedLanguage = value;
            this.$emit("selectedLang", this.selectedLanguage);
            this.isDropdown = false;
        },
        toogleChangeLanguage() {
            this.$emit("selectedLang", this.languages.find(({ value }) => value === (this.prefLang.toLowerCase() == 'id' ? 'en' : 'id')));
        },
        back() {
            this.$router.go(-1);
            this.firstVisit = false;
        },
        documentClick(e){
            let el = this.$refs.dropdownMenu;
            let target = e.target;
            if ( (el !== target) && !el.contains(target)) {
                this.isDropdown = false;
            }
        }
    },
    computed: {
        showBackButton() {
            
            return ![
            "mv-input"
            , "mv-policydetails2"
            , "mv-thankyou"
            , "mv-thankyou2"
            , "mv-redeem"
          ].includes(this.$route.name) && Global.motorVehicle.flagRetrievePolicy === false;
            // (this.$route.name === "mv-input" || this.$route.name === "mv-policydetails2" || this.$route.name === "mv-thankyou" && this.$route.name === "mv-thankyou2" && this.$route.name === "mv-redeem") && (this.$route.name == "mv-payment" && Global.motorVehicle.flagRetrievePolicy === false) );
        },
    }
}
</script>

<style scoped>
.headerMv {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.headerMv .headerContent {
    display: flex;
    width: 100%;
    padding: 16px 0px;
    justify-content: space-between;
}
.headerMv .backButtonMv {
    text-align: left;
    /* padding: 16px 0px; */
    align-self: center;
}
.headerMv .backButtonMv .back {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 4px;
    border-radius: 8px;
    background: #FFEFEB;
    color: #EF4A24;
    text-align: center;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; 
    letter-spacing: 0.28px;
    border: 0;
}
.headerMv .logoBrand {
    padding: 2px 0px;
    text-align: center;
    display: flex;
}
.logoBrand .logo {
    /* margin-left: 10%; */
    /* padding: 18px 0px; */
    align-self: center;
}
.headerMv .language {
    text-align: right;
    padding: 16px 0px;
    display: flex;
}
.stepperHeader {
    margin-top: -15px;
}

.stickyStyle {
    top: 0 !important;
    position:sticky!important;
    z-index: 999;
}

.cur-lang {
    cursor: pointer;
}
.cur-lang .ico{
    margin-right: 4px;
    height: 19px;
    width: 25px;
}
.has-dropdown {
    position: relative;
}
.switch {
  position: relative;
  display: inline-block;
  /* margin: 0 5px; */
}

.switch > span {
  position: absolute;
  top: 10px;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
color: #393939;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #6F6F6F;
}

.backwidth{
    width: 70px !important;
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
color: #393939;
}

.switch > span.off {
  right: 0;
  /* padding-right: 2px; */
  color: #6F6F6F;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 4px;
  width: 60px;
  height: 34px;
  margin-bottom: 0px;
  background-color: #F0F0F0;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  border-radius: 40px;
  border: 1px solid #e8e8e8;
}
input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #F0F0F0;
  
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  color: #393939;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label {
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 28px;
}
.list-lang {
    position: absolute;
    border: 1px solid #ddd;
    z-index: 9999;
    padding: 8px;
    background: #fff;
    border-radius: 4px;
    top: 27px;
    right: 0;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.list-lang .item-lang {
    display: flex;
    padding: 4px;
}
.list-lang .item-lang:hover {
    background-color: #f4feff;
}
.list-lang .item-lang img {
    height: 19px;
    width: 25px;
}
.open {
    transform: rotate(180deg);
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@media screen and (max-width: 480px) {
    .headerMv .headerContent {
        padding: 8px 0px !important;
    }
    /* .headerMv .logoBrand {
        width: 85%;
    }
    .headerMv .language {
        width: 20%;
    }
    .logoBrand .logo {
        margin-left: 15%;
    } */
}
</style>