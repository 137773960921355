<template>
<div id="mv-page">  
  <HeaderMv 
    @selectedLang="setLanguages"
    :showing="isNotRedeem"
    :sticky="isPolicyDetails2"
    :progressbar="isPolicyDetails2"
    :step="steps"
    :labelStepper="isLabelStepperPD2"
    :prefLang = "this.policyObject.root.quoteData.prefLang"
  />
  <TopProgressBar 
    :value-bar="progressBarValue" 
    v-if="isProgressBarShow"
  />
  <router-view></router-view>
  <Footer v-if="isMvInsure"/>
</div>
</template>

<script>
import Global from "@/store/global.js";
import Mixin from "@/components/Mixin";
import { formattedDate, registerIGNSdk, setInterceptorObj, getClientIp  } from "./lib/index.js";
import { policyObject } from "./data/PolicyObject";

// Components
import HeaderMv from "./components/Header.vue";
import TopProgressBar from "./components/TopProgressBar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "MotorVehicle",
  mixins: [Mixin],
  data() {
    return {
      Global,
      policyObject,
      registerReq: {
        "ApiKey": process.env.VUE_APP_MV_API_KEY,
        "SdkVersion": "1.1.0",
        "ProductType": "MotorCar",
        "MasterSetId": ""
      },
      InterceptorObj: null
    }
  },
  components: {
    HeaderMv,
    TopProgressBar,
    Footer,
  },
  async created() {
    document.title = "Motorcar Insurance";
    var vm = this;
    Global.quote.productType = Global.enumProductType.MV;
    Global.motorVehicle.quote.Google_Client_ID = Global.quote.googleClientID;
    this.policyObject.root.quoteData.googleClientId = Global.quote.googleClientID;

    Global.motorVehicle.todayDate = formattedDate();
    const atmoment = new Date();
    Global.motorVehicle.Quote_Date_of_First_Quote = formattedDate(atmoment.toISOString());
    if(this.$route.query.lang !== undefined && ["en", "id"].indexOf(this.$route.query.lang.toLowerCase()) > -1){
      this.policyObject.root.quoteData.prefLang = this.$route.query.lang.toLowerCase();
      this.policyObject.root.quoteData.policyHolderData.prefLang = this.$route.query.lang.toLowerCase();
      this.policyObject.root.quoteData.insuredData.prefLang = this.$route.query.lang.toLowerCase();
      Global.quote.preflang = this.$route.query.lang.toLowerCase();
      this.$root.$i18n.locale = this.$route.query.lang.toLowerCase();
    }else{
      this.policyObject.root.quoteData.prefLang = "id"
      this.policyObject.root.quoteData.policyHolderData.prefLang = "id"
      this.policyObject.root.quoteData.insuredData.prefLang = "id"
    }
    try {
      await registerIGNSdk(vm.registerReq);
      Global.motorVehicle.interceptorObj = vm.InterceptorObj;
      // const iPAddress = await getClientIp();
      // Global.motorVehicle.phaccount.iPAddress = iPAddress;
    } catch (error) {
      vm.loading("hide");
      vm.errorModal(vm.$t("error.connection.message"));
    }
    if (sessionStorage.getItem("channel") !== null) {
      Global.quote.channel = sessionStorage.getItem("channel");
      this.policyObject.root.quoteData.channel = sessionStorage.getItem("channel");
    }
    if (this.$route.query.utm_source !== undefined){
      Global.utmSource = this.$route.query.utm_source;
    }
    if (this.$route.query.channel !== undefined){
      Global.decryptReferralLink = this.$route.query.channel;
    }
    if(this.$route.query.productAffiliate !== undefined){
      Global.productAffiliate = this.$route.query.productAffiliate
    }
    // this.$router.replace({name: 'mv-input'});
  },
  mounted(){
    this.eventTracking('startQuote', 'quotationClick', 'Startquote', 'CAR');
  },
  methods: {
    setLanguages(value) {
      Global.quote.prefLang = value.value;
      Global.motorVehicle.quote.prefLang = value.value;
      this.policyObject.root.quoteData.prefLang = value.value;
      this.policyObject.root.quoteData.policyHolderData.prefLang = value.value;
      this.policyObject.root.quoteData.insuredData.prefLang = value.value;
      this.$root.$i18n.locale = value.value;
    }
  },
  computed: {
    isPolicyDetails2(){
      return this.$route.name === "mv-policydetails2" || this.$route.name === "mv-thankyou" || this.$route.name === "mv-thankyou2";
    },
    isNotRedeem(){
      return this.$route.name !== "mv-redeem";
    },
    steps(){
      return this.$route.name === "mv-thankyou2" ? 3 : this.$route.name === "mv-policydetails2" ? 2 : 5;
    },
    isProgressBarShow() {
      return this.$route.name === "mv-input";
    },
    progressBarValue() {
      const steps = Global.steps.length + 1;
      const progress = Math.floor((steps / 16) * 100); 
      return progress;
    },
    isMvInsure() {
      return Global.quote.productType === "MotorCar";
    },
    isLabelStepperPD2() {
      if(Global.motorVehicle.scrollPositionPD2 > 60) {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
#mv-input {
  margin-top: 20px;
  margin-bottom: 20px;
}
/* #mv-page{
  font-family:'Public Sans';
} */
.progress-step {
  margin: 0px 25px;
}
.section {
  max-width: 630px;
  width: 100%;
  padding: 0 15px;
  margin: auto;
}
.section-car-selection {
  text-align: center;
  margin-top: 31px;
}
.dash-camera-section {
  padding: 90px 0px;
}
.milage-section {
  padding: 90px 0px;
}
.purpose-section {
  padding: 90px 0px;
}
.car-for-work {
  padding: 90px 0px;
}
.claim-section {
  padding: 90px 0px;
}
.gender-section {
  padding: 90px 0px;
}
.dob-section {
  padding: 90px 0px;
}
.driving-license-section {
  padding: 90px 0px;
}
.post-code-section {
  padding: 90px 0px;
}
.premium-section {
  padding: 90px 0px;
}
</style>