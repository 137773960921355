<template>
<div class="steps">
    <div class="wizard-progress">
        <div class="step" :class="{complete : activeLink(item.id)}" v-for="(item, index) in stepLists" :key="index">
            <div class="node"></div>
            <div class="text-stepper" v-if="isShowLabel">{{ $t('motorVehicle.' + item.title) }}</div>
            
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: "Stepper",
    props: {
        steps: {
            type: Number,
            default: 1
        },
        language: {
            type: String,
            default: 'id'
        },
        isShowLabel: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            stepLists: [
                {
                    id: 1,
                    title: 'stepperFirst',
                },
                {
                    id: 2,
                    title: 'stepperSecond',
                },
                {
                    id: 3,
                    title: 'stepperThird',
                },
                {
                    id: 4,
                    title: 'stepperFourth',
                },
                {
                    id: 5,
                    title: 'stepperFifth',
                },
            ],
        }
    },
    computed: {
        progressValue() {
            switch(this.currentStep) {
                case 1:
                    return 0;
                    break;
                case 2:
                    return 35;
                    break;
                case 3: 
                    return 69;
                    break;
                case 4:
                    return 100;
                    break;
            }
        },
        currentStep() {
            return this.steps;
        },
    },
    methods: {
        isDone(value) {
            return value < this.currentStep;
        },
        activeLink(value) {
            return value == this.currentStep || value < this.currentStep;
        },
        moveTo(value) {
            this.$emit('moveTo', value);
        },
    }
}
</script>

<style>
    .wizard-progress{
        display: table;
        width: 100%;
        table-layout: fixed;
        position:absolute;
        top: 88px;
    }
    .step{
        display: table-cell;
        text-align: center;
        overflow: visible;
        position:relative;
        font-size: 14px;
        color: #CCCCCC;
    }
    
    .step:not(:last-child):before{
        content: '';
        display:block;
        position: absolute;
        left: 50%;
        top: 10px;
        background-color: #CCCCCC;
        height: 6px;
        width: 100%;
    }

    .text-stepper{
        font-size: 16px;
        margin-top: 30px;
        margin-left:-10px;

        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    
    .node{
        display: inline-block;
        border: 6px solid #CCCCCC;
        background-color: #CCCCCC;
        border-radius: 16px;
        height: 16px;
        width: 16px;
        position: absolute;
        top: 5px;
        left: 50%;
        margin-left: -14px;
    }
    
    .step.complete .node{
        border-radius: 30px;
        height: 30px;
        width: 30px;
        position: absolute;
        top: 0px;
        left: 50%;
        margin-left: -28px;
        border-color: #FF4713;
        background-color: #FF4713;
    
    }
    .step.complete:before{
        background-color: #FF4713;
    }
    .step.complete .node{
        border-color: #FF4713;
        background-color: #FF4713;
    }
    .step.complete .node:before{
        content: "\E207";
        color:#fff;
        font-family: 'Glyphicons Regular';
    }
    @-webkit-keyframes fadeIn {
        0% {opacity: 0;}
        100% {opacity: 1;}
    }
    @keyframes fadeIn {
        0% {opacity: 0;}
        100% {opacity: 1;}
    } 
    @media screen and (max-width: 482px) {
        .text-stepper{
            font-size: 10px;
            margin-top: 30px;
            margin-left:-10px;
        }
        .wizard-progress{
            top: 75px !important;
        }
    }
</style>