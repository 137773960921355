<template>
<div id="progress-line-area" class="text-center px-0 sticky-top">
    <div class="progress">
        
        <div class="progress-bar" :style="'width:'+ valueBar +'%;'"></div>
    </div>
</div>
</template>

<script>
export default {
    name: "TopProgressBar",
    props: {
        valueBar: {
            type: [String, Number]
        }
    }
}
</script>

<style scoped>
#progress-line-area {
    height: 20px;
    margin-bottom: 0px;
    border-radius: 0;
    margin-top: 0px;
    width: 100%;
}
#progress-line-area.fixed{
  position: fixed;
  margin-top: 0;
  left: 0;
  top: 0;
  z-index: 1100;
}
#progress-line-area .progress {
  border-radius: 0;
  height: 20px;
  margin-bottom: 0px;
}
#progress-line-area .progress .progress-bar {
  background-image: linear-gradient(to right, rgba(1,92,175,1), rgba(71,167,255,1));
}

</style>